import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('it'); // Default language is Italian
  
  const switchLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const { latitude, longitude } = coords;
      // Make an API call to a geolocation service to get the user's country based on their coordinates
      // You can use services like Google Maps Geocoding API or GeoNames API
      // Example API call using fetch:
      fetch(`https://api.geonames.org/countryCodeJSON?lat=${latitude}&lng=${longitude}&username=your_username`)
        .then(response => response.json())
        .then(data => {
          const countryCode = data.countryCode;
          if (countryCode === 'IT') {
            setLanguage('it');
          } else {
            setLanguage('en');
          }
        })
        .catch(error => {
          console.error('Error fetching country code:', error);
          setLanguage('it'); // Set language to Italian if there's an error
        });
    } else {
      // Fallback to browser language detection
      const browserLanguage = navigator.language || navigator.userLanguage;
      if (browserLanguage.startsWith('en')) {
        setLanguage('en');
      } else {
        setLanguage('it');
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext);