import React from 'react';
import { Box } from '@mui/material';

const ChiSiamo = () => {
  return (
    <Box sx={{ pt: '128px' }}> {/* Adjust the value based on your navbar's actual height */}
    {/* Home page content goes here */}
      <h1>prodotti</h1>
    </Box>
  );
};


export default ChiSiamo;
