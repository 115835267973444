import React from 'react';
import { Box } from '@mui/material';

const Privacy = () => {
  return (
    <Box sx={{ pt: '128px' }}> {/* Adjust the value based on your navbar's actual height */}
    {/* Home page content goes here */}
      <h1>Privacy</h1>




      <h1>Privacy</h1>




      <h1>Privacy</h1>
      
    </Box>
  );
};

export default Privacy;

