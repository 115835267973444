import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Pcn from './pages/Pcn';
import Prodotti from './pages/Prodotti';
import News from './pages/News';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import { LanguageProvider, useLanguage } from './LanguageContext';
import Cards from './pages/cards';
import Phone_topups from './pages/phone_top-ups';
import Contact_us from './pages/Contact_us';
import Terms from './pages/Terms';
import Assistance from './pages/Assistance';
import Intercom from '@intercom/messenger-js-sdk';
import CookieConsent from 'react-cookie-consent';
import { Typography } from '@mui/material';
import translations from './translations';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
  },
});

function App() {
  const { language } = useLanguage();

  useEffect(() => {
    Intercom({
      app_id: 'g3g0349a',
    });
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ 
        maxWidth: '1920px', 
        margin: '0 auto',
        position: 'relative',
        overflow: 'hidden', // This will hide anything that goes beyond 1920px
      }}>
        <Router>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            bgcolor: 'background.default',
          }}>
            <Box sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1100,
              width: '100%',
            }}>
              <Navbar />
            </Box>
            
            <Box component="main" sx={{ 
              flexGrow: 1, 
              width: '100%', 
              padding: '0 16px', // Add some padding on the sides
            }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="pcn" element={<Pcn />} />
                <Route path="prodotti" element={<Prodotti />} />
                <Route path="news" element={<News />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/cards" element={<Cards />} />
                <Route path="/phone_top-ups" element={<Phone_topups />} />
                <Route path="/contact_us" element={<Contact_us />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/assistance" element={<Assistance />} />
              </Routes>
            </Box>
            
            <Footer />
            
            <CookieConsent
              location="bottom"
              buttonText={translations[language]["Accept"]}
              declineButtonText={translations[language]["Decline"]}
              enableDeclineButton
              cookieName="myAwesomeCookieName"
              style={{
                backgroundColor: '#ffffff',
                color: '#000000',
                borderRadius: '10px',
                fontSize: '1rem',
                padding: '10px 30px',
                textTransform: 'none',
                maxWidth: '1920px',
                margin: '0 auto',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
              buttonStyle={{
                backgroundColor: '#333333',
                color: '#ffffff',
                borderRadius: '5px',
                padding: '8px 16px',
                marginRight: '10px',
              }}
              declineButtonStyle={{
                backgroundColor: '#ffffff',
                color: '#333333',
                borderRadius: '5px',
                padding: '8px 16px',
                border: '1px solid #333333',
              }}
              expires={150}
            >
              <Typography variant="body1" sx={{ fontSize: { xs: '0.4rem', sm: '0.6rem', md: '0.8rem', lg: '1rem' }, flexGrow: 1 }}>
                {translations[language]["Cookie text"]}
              </Typography>
            </CookieConsent>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default function AppWrapper() {
  return (
    <LanguageProvider>
      <App />
    </LanguageProvider>
  );
}