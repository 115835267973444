import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Modal, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';

function Footer() {
    const { language } = useLanguage();
    const [openModal, setOpenModal] = useState(null);
    const [privacyItPdfUrl, setPrivacyItPdfUrl] = useState('');
    const [privacyEnPdfUrl, setPrivacyEnPdfUrl] = useState('');
    const [termsItPdfUrl, setTermsItPdfUrl] = useState('');
    const [termsEnPdfUrl, setTermsEnPdfUrl] = useState('');
    const [termsgpPdfUrl, setTermsgpPdfUrl] = useState('');
    const [termsapPdfUrl, setTermsapPdfUrl] = useState('');
    const [termsunitPdfUrl, setTermsunitPdfUrl] = useState('');
    const [termsunienPdfUrl, setTermsunienPdfUrl] = useState('');
    const [CookieenPdfUrl, setCookieenPdfUrl] = useState('');
    const [CookieitPdfUrl, setCookieitPdfUrl] = useState('');


    const handleOpenModal = (modalType) => {
        setOpenModal(modalType);
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    useEffect(() => {
        const fetchPdfUrl = (file, setter) => {
            setter(`/docs/${file}`);
        };

        fetchPdfUrl('Privacyit.pdf', setPrivacyItPdfUrl);
        fetchPdfUrl('Privacyen.pdf', setPrivacyEnPdfUrl);
        fetchPdfUrl('Terminiecoit.pdf', setTermsItPdfUrl);
        fetchPdfUrl('Terminiecoen.pdf', setTermsEnPdfUrl);
        fetchPdfUrl('Terminiecogp.pdf', setTermsgpPdfUrl);
        fetchPdfUrl('Terminiecoap.pdf', setTermsapPdfUrl);
        fetchPdfUrl('Terminiunit.pdf', setTermsunitPdfUrl);
        fetchPdfUrl('Terminiunen.pdf', setTermsunienPdfUrl);
        fetchPdfUrl('Cookieit.pdf', setCookieitPdfUrl);
        fetchPdfUrl('Cookieen.pdf', setCookieenPdfUrl);


    }, []);

    const getPdfUrl = () => {
        switch (openModal) {
            case 'privacyit':
                return privacyItPdfUrl;
            case 'privacyen':
                return privacyEnPdfUrl;
            case 'terminiecoit':
                return termsItPdfUrl;
            case 'terminiecoen':
                return termsEnPdfUrl;
            case 'terminiecogp':
                return termsgpPdfUrl;
            case 'terminiecoap':
                return termsapPdfUrl;
            case 'terminiunit':
                return termsunitPdfUrl;
            case 'terminiunen':
                return termsunienPdfUrl;
            case 'cookieit':
                return CookieitPdfUrl;
            case 'cookieen':
                return CookieenPdfUrl;
            default:
                return '';
        }
    };

    return (
        <Box
            component="footer"
            sx={{
                position: 'relative',
                backgroundImage: {
                    xs: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url('/images/Footer1.webp')",
                    sm: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url('/images/Footer1.webp')",
                    md: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)), url('/images/Footer1.webp')",
                },
                backgroundSize: {
                    xs: '100%',
                    sm: 'contain',
                    md: 'contain',
                },
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000000',
                py: { xs: 32, sm: 25, md: 36 },
                px: { xs: 2, sm: 4, md: 6 },
                color: '#FFFFFF',
                fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' },
                fontWeight: 400,
                width: '100%',
                textAlign: 'center',
                overflow: 'hidden',
                maxHeight: '300px', 
            }}
        >
            <Grid
                container
                spacing={4}
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    pt: { xs: 2, sm: 4, md: 6 },
                    pl: { xs: 10, sm: 13, md: 16, lg: 20 },
                    pr: { xs: 2, sm: 4, md: 6 },
                }}
            >
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {translations[language]['AZIENDA']}
                    </Typography>
                    <Link to="/about" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]['Chi siamo']}
                    </Link>
                    <Box onClick={() => window.open('https://www.casarealeholding.com/', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' }, }}>
                            {translations[language]['Valori']}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {translations[language]['LEGALE']}
                    </Typography>
                    <Link href="#" onClick={() => handleOpenModal('privacyit')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Privacy IT"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('privacyen')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Privacy EN"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiecoit')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Termini e condizioni IT"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiecoen')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Terms and Conditions EN"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('cookieit')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Cookie IT"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('cookieen')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Cookie EN"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiecogp')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Termini e condizioni Googlepay"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiecoap')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Termini e condizioni Applepay"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiunit')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Termini e condizioni Unlimit It"]}
                    </Link>
                    <Link href="#" onClick={() => handleOpenModal('terminiunen')} style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]["Termini e condizioni Unlimit EN"]}
                    </Link>
                    <Box onClick={() => window.open('https://guidautente.cartareale.it/it/knowledge/foglio-informativo-conto-carta-reale', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                            {translations[language]['Documento sui costi di Carta Reale']}
                        </Typography>
                    </Box>
                    <Box onClick={() => window.open('https://guidautente.cartareale.it/en/knowledge/carta-reale-account-information-sheet', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' }, }}>
                            {translations[language]['Carta Reale Cost Document']}
                        </Typography>
                    </Box>

                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {translations[language]['MARKETING']}
                    </Typography>
                    <Link to="/cards" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]['Carte']}
                    </Link>
                    <Link to="/news" style={{ textDecoration: 'none', color: 'inherit', alignSelf: 'flex-start' }}>
                        {translations[language]['News']}
                    </Link>
                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {translations[language]['CARTA REALE']}
                    </Typography>
                    <Link to="/cards" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]['Piani']}
                    </Link>
                    <Link to="/assistance" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {translations[language]['Assistenza']}
                    </Link>
                    <Box onClick={() => window.open('https://guidautente.cartareale.it/it/knowledge', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' }, }}>
                            {translations[language]['Guida Utente IT']}
                        </Typography>
                    </Box>
                    <Box onClick={() => window.open('https://guidautente.cartareale.it/en/knowledge', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' }, }}>
                            {translations[language]['Guida Utente EN']}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={openModal !== null}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 2,
                        }}
                    >
                        <img src="/images/Stemmaco.webp" alt="Stemmaco" style={{ maxWidth: '4%', height: 'auto' }} />
                    </Box>
                    <Box sx={{ height: '100%' }}>
                        <iframe
                            src={getPdfUrl()}
                            title="PDF Viewer"
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    </Box>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Box>
            </Modal>
            <Box sx={{ mt: { xs: 8, sm: 10, md: 10, lg: 12 } }}>
                <Typography
                    variant="h3"
                    sx={{
                        fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' },
                        color: '#ffffff',
                        textAlign: 'center',
                        lineHeight: 1.6,
                        padding: { xs: 4, sm: 8, md: 4, lg: 5 },

                    }}
                >
                    {translations[language]["Il conto Carta Reale e Mastercard sono emessi da Unlimint, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unlimint diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori."]}

                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;