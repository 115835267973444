import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  palette: {
    text: {
      primary:'#FFFFFF', // Sets default text color to your custom color
    },
    // other palette settings...
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Poppins, sans-serif',backgroundColor: '#000000',
        },
      },
    },
  },
});

export default theme;