import React from 'react';
import { Grid, Button, Box, Typography, useMediaQuery, Accordion, AccordionSummary, useTheme, AccordionDetails, Container } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import translations from '../translations';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';

const Homepage = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleImageClick = (imageName) => {
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

    if (isMobile) {
      if (imageName === 'Home94' && isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
      } else if (imageName === 'Home93' && isIOS) {
        window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
      }
    }
  };

  const handleApriIlConto = () => {
    if (isMobile) {
      const isAndroid = /android/i.test(navigator.userAgent);
      const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

      if (isAndroid) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
        }, 500);
      } else if (isIOS) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
        }, 500);
      }
    } else {
      window.open('https://my.cartareale.it/register', '_blank');
    }
  };


  return (
    <>
      <Box
        sx={{
          marginTop: {
            xs: '80px',
            sm: '80px',
            md: '80px',
            lg: '80px',
          },
          paddingTop: {
            xs: '20%',
            sm: '180px',
            md: '80px',
            lg: '80px',
          },
          backgroundImage: {
            xs: `url('/images/Home1M.webp')`,
            sm: `url('/images/Home1M.webp')`,
            md: `url('/images/Home1.webp')`,
            lg: `url('/images/Home1.webp')`,
          },
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          minHeight: {
            xs: '300px',
            sm: '400px',
            md: '500px',
            lg: '600px',
          },
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
              pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#000000',
                mt: { xs: 50, sm: 70, md: 0, lg: 0 },
                mb: { xs: 2, sm: 5, md: 2, lg: '4rem' },
              }}
            >

              {translations[language]['more_than_a_payment_app_it_is_carta_reale']}
            </Typography>

            <Typography
              variant="h2"
              sx={{
                color: '#000000',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                mb: { xs: 2, sm: 2, md: 2, lg: '4rem' },
              }}
            >
              {translations[language]['Il tuo denaro e i tuoi dati']}<br />
              {translations[language]['sono al sicuro con Carta Reale']}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mb: 4,
                color: '#000000',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                mb: { xs: 2, sm: 5, md: 2, lg: '4rem' },
              }}
            >
              {translations[language]['Scopri un mondo esclusivo']}<br />
              {translations[language]["con una storia che inizia"]}<br />
              {translations[language]['più di mille anni fa...']}
            </Typography>
            <Button
              component="a"  // Specify that the Button behaves like an anchor tag
              onClick={handleApriIlConto}
              target="_blank"  // Open the link in a new tab/window
              rel="noopener noreferrer" // Add necessary security attributes when opening in a new tab/window
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                color: '#000000',
                borderRadius: '10px',
                fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' },
                mb: { xs: 2, sm: 4, md: 1, lg: 5 },
                padding: '10px 30px',
                textTransform: 'none',
                '&:hover': { backgroundColor: '#ffffff' },
              }}
            >
              {translations[language]['APRI IL CONTO']}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 8, px: { xs: 10, md: 8 }, backgroundColor: '#000000', color: '#ffffff' }}>
        <Box
          sx={{
            border: '2px solid transparent',
            borderRadius: '8px',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' }, mb: 2 }}>
            {translations[language]["Un'esperienza esclusiva"]}
            <br />
            {translations[language]["di pagamento"]}
          </Typography>
          <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.25rem', lg: '2.25rem' } }}>
            {translations[language]['Usa Carta Reale, il conto corrente']}
            <br />
            {translations[language]['per la gestione finanziaria di tutti i giorni.']}
          </Typography>
        </Box>
        <Grid container spacing={4} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
              <img
                src="/images/Home21.svg"
                alt={translations[language]['Products Icon']}
                style={{ width: '50px', height: 'auto', marginBottom: '1rem' }}
              />
              <Box>
                <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                  {translations[language]['Libertà']}
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                  {translations[language]["Usa il tuo conto corrente senza limiti annuali, dallo smartphone, dal computer o dal tablet e paga con bonifici europei, internazionali. Apri fino a 2 carte fisiche e 4 virtuali, aggiungile al tuo wallet e paga e preleva senza confini."]}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
              <img
                src="/images/Home24.svg"
                alt={translations[language]['Products Icon']}
                style={{ width: '40px', height: 'auto', marginBottom: '1rem' }}
              />
              <Box>
                <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                  {translations[language]["Sicurezza"]}
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                  {translations[language]["Investiamo molto in sicurezza per la tua serenità. I tuoi soldi sono al sicuro in Carta Reale, usiamo la migliore tecnologia informatica e lo schema di protezione dei fondi come da regole della Banca Centrale Europea."]}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
              <img
                src="/images/Home22.svg"
                alt={translations[language]['Products Icon']}
                style={{ width: '50px', height: 'auto', marginBottom: '1rem' }}
              />
              <Box>
                <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                  {translations[language]["Ricco di funzioni"]}
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                  {translations[language]["recharge_functions"]}<br></br>
                  {translations[language]["service_available_from"]}
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                  {translations[language]["massimale_giornaliero"]}
                  <br></br>
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
              <img
                src="/images/Home23.svg"
                alt={translations[language]['Products Icon']}
                style={{ width: '50px', height: 'auto', marginBottom: '1rem' }}
              />
              <Box>
                <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                  {translations[language]["Assistenza"]}
                </Typography>
                <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                  {translations[language]["Ti assistiamo sempre, dall’ apertura del conto corrente alla risoluzione di ogni eventuale problema, la ricca documentazione e il supporto rapido via chat, telefono o mail, ti faranno apprezzare il nostro servizio premium."]}
                </Typography>
              </Box>


            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{
        backgroundImage: {
          xs: `url('/images/Home3M.webp')`,
          sm: `url('/images/Home3.webp')`,
          md: `url('/images/Home3.webp')`,
          lg: `url('/images/Home3.webp')`,
        },
        backgroundSize: {
          xs: 'cover',
          sm: 'cover',
          md: 'cover',
          lg: 'cover',
        },
        backgroundPosition: {
          xs: 'bottom center',
          sm: 'bottom center',
          md: 'bottom center',
          lg: 'center',
        },
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}>

        <Grid container justifyContent="flex-start">
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Centers children vertically within the grid item
            alignItems: 'center', // Centers children horizontally within the grid item
            textAlign: 'center', // Centers text within its container

            pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
            pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
            pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
          }}>
            <Typography variant="h1" sx={{
              fontWeight: 'bold',
              fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
              color: '#000000',
              mt: { xs: 40, sm: 0, md: 10, lg: 8 },
              mb: { xs: 2, sm: 5, md: 2, lg: 3 },
              textAlign: 'center',
            }}>
              {translations[language]['Conformità']}<br />
              {translations[language]['e Sicurezza']}
            </Typography>
            <Typography variant="h2" component="ul" sx={{
              listStylePosition: 'inside', // Positions the bullet points inside the text block
              textAlign: 'left', // Ensures the text (and bullets) are centered
              mb: { xs: 2, sm: 5, md: 10, lg: 15 },
              color: '#000000',
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
              pl: 2,

              // Apply additional styling as needed
            }}>

              <li>{translations[language]['Conformità alle regole Europee']}</li>
              <li>{translations[language]['Controllo degli accessi ']}</li>
              <li>{translations[language]['Certificazione Crest di cyber sicurezza']}</li>
              <li>{translations[language]['Password dinamiche per gli acquisti Online']}</li>
            </Typography>
            <Typography variant="h3" sx={{

              color: '#000000',
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
              mb: { xs: 2, sm: 5, md: 2, lg: 3 },
              textAlign: 'center',
            }}>
              {translations[language]['La nostra missione è permetter alle persone']}<br />
              {translations[language]["di vivere e lavorare ovunque,con semplicità,"]}<br />
              {translations[language]['offrendo la massima sicurezza.']}
            </Typography>
            <Box sx={{ width: { xs: '50%', sm: '40%', md: '30%', lg: '80%' } }}>
              <img
                src="/images/Home31.webp"
                alt="Home31"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ py: 6, px: { xs: 4, md: 8 }, backgroundColor: '#000000', color: '#ffffff' }}>
        <Box
          sx={{
            border: '2px solid transparent',
            borderRadius: '8px',
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'center',
          }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h1" sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.5rem', sm: '3rem', md: '2rem', lg: '3rem' },
                color: '#ffffff',
                mt: { xs: 5, sm: 0, md: 2, lg: 5 },
                mb: { xs: 2, sm: 5, md: 2, lg: 5 },
                textAlign: 'center',
              }}>
                {translations[language]['Apri il tuo conto']}<br />
                {translations[language]['in meno di 10 minuti']}
              </Typography>
              <Button
                component="a"  // Specify that the Button behaves like an anchor tag
                onClick={handleApriIlConto}
                target="_blank"  // Open the link in a new tab/window
                rel="noopener noreferrer" // Add necessary security attributes when opening in a new tab/window
                variant="contained"
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  borderRadius: '10px',
                  fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  mb: { xs: 2, sm: 5, md: 1, lg: 5 },
                  padding: '10px 30px',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: '#ffffff' },
                }}
              >
                {translations[language]['APRI IL CONTO']}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>


      <Box sx={{ color: '#000000', mb: '0.5rem' }}>
        <Box sx={{
          position: 'relative',
          backgroundColor: '#dfe0e2', // Ensure the base color covers the entire Box
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url('/images/Stemmasf.svg')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            opacity: 0.15, // Adjust the opacity as needed
          },
          // Ensure the content of the box is positioned above the pseudo-element
          '> *': { position: 'relative', zIndex: 1 },
        }}>

          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center', // This aligns children vertically in the center
              alignItems: 'center', // Change from 'flex-start' to 'center' for horizontal centering if desired
              textAlign: 'center', // Aligns text center horizontally
              pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pt: { xs: '1rem', sm: '3rem', md: '4rem', lg: '7rem' },
              pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
            }}>
              <Box sx={{ width: { xs: '50%', sm: '40%', md: '50%', lg: '45%' } }}>
                <img src="/images/Home52.webp" alt="Home31" style={{ width: '100%', height: 'auto' }} />
              </Box>
              <Typography variant="h1" sx={{
                fontWeight: 'bold',
                fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
                color: '#000000',
                mt: { xs: 5, sm: 0, md: 3, lg: 4 },
                mb: { xs: 2, sm: 5, md: 2, lg: 2 },
              }}>
                {translations[language]['Scegli la tua']}<br />
                {translations[language]['Carta Reale']}
              </Typography>
              <Button
                component="button" // Change the component to a regular button
                onClick={() => navigate('/cards')} // Navigate to the "Cards" page when clicked
                variant="contained"
                sx={{
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  borderRadius: '10px',
                  fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  mb: { xs: 2, sm: 5, md: 1, lg: 5 },
                  padding: '10px 30px',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: '#ffffff' },
                }}
              >
                {translations[language]['SCOPRI LE CARTE']}
              </Button>
              <Typography variant="h3" sx={{
                mb: { xs: 2, sm: 2, md: 1, lg: 4 },
                color: '#000000',
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
                textAlign: 'left',
              }}>
                {translations[language]["* solo su invito"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
              pt: { xs: '0rem', sm: '3rem', md: '4rem', lg: '5rem' },

            }}>
              <Box sx={{
                width: { xs: '50%', sm: '40%', md: '80%', lg: '100%' },
                height: 'auto',
              }}>
                <img
                  src="/images/Home61.webp"
                  alt="Home51"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              mr: { xs: '1rem', sm: '2rem', md: '3rem', lg: '4rem' },
            }}>
              <Typography
                variant="h3"
                sx={{
                  mb: { xs: 1, sm: 1, md: 2 },
                  color: '#000000',
                  fontSize: { xs: '1.1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                  textAlign: { xs: 'center', sm: 'center', md: 'left' },
                  fontWeight: 'bold',
                  mr: { md: '1rem', lg: '1rem' },
                }}
              >
                {translations[language]["Aggiungi Carta Reale al tuo wallet"]}<br />
                {translations[language]["Apple o Google per pagare e prelevare"]}
              </Typography>
              <Box
                sx={{

                  mb: { xs: 1, sm: 1, md: 2 },
                  width: { xs: '50%', sm: '50%', md: '26%', lg: '20%' },
                  height: 'auto',
                  mt: { xs: 2, sm: 2, md: 0 },
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src="/images/Home64.webp"
                  alt="Home64"
                  style={{ width: '80%', height: 'auto' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>


      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: '100vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to right, #121829, #acaeb4)',
            zIndex: -2,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: {
              xs: 'none',
              md: `url('/images/s.webp')`,
              lg: `url('/images/s.webp')`,
            },
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: -1,
          },
        }}
      >

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'stretch', mb: { xs: 4, sm: 4, md: 6, lg: 4 }, gap: { md: 10, lg: 20 }, mx: { xs: 7, sm: 15, md: 10, lg: 10 }, }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            textAlign: 'center',
            px: { md: 4 },
            border: '2px solid white',
            backgroundColor: '#000000',
            marginBottom: { xs: '2rem', md: 0 },
            width: { xs: '100%', md: '30%' },
            mt: '2rem',
            color: '#ffffff',


          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]['Attiva in 5 minuti']}
            </Typography>
            <Typography variant="h3" sx={{
              fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Ti bastano un documento (carta d’Identità o Passaporto ) , una prova di indirizzo e un selfie per aprire il conto digitale al 100% di Carta Reale, inoltre l’assistenza è sempre a tua disposizione per aiutarti"]}
            </Typography>
            <Box sx={{
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home71.svg"
                alt="Home64"
                style={{ width: '30%', height: 'auto' }}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            textAlign: 'center',
            px: { md: 4 },
            border: '2px solid white',
            backgroundColor: 'rgba(30, 144, 255, 0.8)',
            marginBottom: { xs: '2rem', md: 0 },
            width: { xs: '100%', md: '30%' },
            mt: '2rem',
          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Conto Europeo"]}
            </Typography>
            <Typography variant="h3" sx={{
              mb: '1.5rem',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Ti forniamo un conto con IBAN europeo (Cipro) per i tuoi trasferimenti senza limiti di importo in tutta Europa e nel mondo"]}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home72.svg"
                alt="Home64"
                style={{ width: '35%', height: 'auto' }}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            textAlign: 'center',
            px: { md: 4 },
            border: '2px solid black',
            backgroundColor: '#ffffff',
            marginBottom: { xs: '2rem', md: 0 },
            width: { xs: '100%', md: '30%' },
            mt: '2rem',
          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              color: '#000000',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Pagamenti utenze"]}
            </Typography>
            <Typography variant="h3" sx={{
              color: '#000000',
              mb: '1.5rem',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Paga dall' App, tablet o dal computer le tue utenze, il bollo auto, PagoPA*, i bollettini premarcati semplicemente in modo sicuro."]}<br />
              {translations[language]["service_available_from"]}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home73.svg"
                alt="Home64"
                style={{ width: '40%', height: 'auto' }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center', alignItems: 'stretch', mb: { xs: 4, sm: 4, md: 6, lg: 4 }, gap: { md: 10, lg: 20 }, mx: { xs: 7, sm: 15, md: 10, lg: 10 }, }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            px: { md: 4 },
            border: '2px solid white',
            backgroundColor: 'rgba(30, 144, 255, 0.8)',
            marginBottom: { xs: '2rem', md: '2rem' },
            width: { xs: '100%', md: '30%' },
          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Sicurezza"]}
            </Typography>
            <Typography variant="h3" sx={{
              mb: '1.5rem',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
              textAlign: 'center',
            }}>
              {translations[language]["Non regaliamo punti, non ti diamo premi, non abbiamo canoni zero, perché a differenza degli altri servizi finanziari on line, non usiamo i tuoi soldi, che vengono depositati in conti di salvaguardia regolamentati dalla BCE."]}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home74.svg"
                alt="Home64"
                style={{ width: '35%', height: 'auto' }}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            textAlign: 'center',
            px: { md: 4 },
            border: '2px solid black',
            backgroundColor: '#ffffff',
            marginBottom: { xs: '2rem', md: '2rem' },
            width: { xs: '100%', md: '30%' },
          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              color: '#000000',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Carte Mastercard"]}
            </Typography>
            <Typography variant="h3" sx={{
              mb: '1.5rem',
              color: '#000000',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Ottieni fino a 4 carte virtuali e 2 fisiche, usale con il tuo smartphone o tablet per pagare e prelevare in libertà in tutto il mondo."]}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home75.svg"
                alt="Home64"
                style={{ width: '35%', height: 'auto' }}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '2rem',
            textAlign: 'center',
            px: { md: 4 },
            border: '2px solid white',
            backgroundColor: '#000000',
            color: '#ffffff',
            marginBottom: { xs: '2rem', md: '2rem', },
            width: { xs: '100%', md: '30%' },

          }}>
            <Typography variant="h2" sx={{
              mb: '0.8rem',
              fontWeight: 'bold',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["WEB"]}
            </Typography>
            <Typography variant="h3" sx={{
              mb: '1.5rem',
              fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem', lg: '1rem' },
            }}>
              {translations[language]["Accedi a tutte le funzionalità anche dal Web senza costi aggiuntivi godendo di tutte le funzionalità dell’APP, facilmente."]}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{
              width: { xs: '80%', sm: '60%', md: '40%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home76.svg"
                alt="Home64"
                style={{ width: '35%', height: 'auto' }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ py: '0.25rem', }}></Box>


      <Box sx={{
        position: 'relative',
        width: '100%', // Ensure the Box takes full width
        minHeight: '100vh',// Example height, adjust as needed
        backgroundColor: '#ffffff',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '50%',
          backgroundColor: '#ffffff',
          backgroundImage: `linear-gradient(to right, #8b8a88 0%, #ffffff 100%)`, // Gradient from left to right
          opacity: 1, // Full opacity for the gradient
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0, // Adjusted to position the image on the left
          bottom: 0, // Maintains the image within the left half of the Box
          backgroundImage: `url('/images/Stemmasf.svg')`,
          backgroundPosition: 'center top',
          mt: '20rem',
          backgroundRepeat: 'no-repeat',
          width: '50%',
          backgroundSize: '50%',
          opacity: 0.15, // Adjust the opacity as needed for the image
        },
        // Ensure the content of the box is positioned above the pseudo-elements
        '> *': { position: 'relative', zIndex: 1 },
      }}>

        <Box sx={{

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          textAlign: 'left',
          justifyContent: 'center',
          px: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' } // Consistent horizontal padding
        }}>

          <Typography variant="h1" sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
            color: '#000000',
            mt: { xs: 5, sm: 0, md: 10, lg: 5 },
            mb: { xs: 0, sm: 0, md: 0, lg: 0 },
            textAlign: 'left',
          }}>
            {translations[language]["Scarica l'App Carta Reale"]}
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'center',
            pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
            pt: { xs: '0rem', sm: '3rem', md: '4rem', lg: '3rem' },
            pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
          }}>
            <Box sx={{ mt: '0rem', alignItems: 'left', pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '20rem' } }}>
              <Typography variant="h2" sx={{
                mb: 4,
                color: '#000000',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                textAlign: 'left',

              }}>
                {translations[language]["Trovi Carta Reale nell' APpp Store e su Google Play oppure inquadrando il Qr Code. Apri il tuo conto in 5 minuti, ottieni la tua carta virtuale per operare da subito"]}
              </Typography>
              <Box
                sx={{
                  width: { xs: '70%', sm: '70%', md: '80%', lg: '100%' },
                  height: 'auto',
                  mt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '15rem' },
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}
              >
                <img
                  src="/images/Home93.svg"
                  alt="Home51"
                  style={{ width: '65%', height: 'auto', marginRight: '1rem', cursor: 'pointer' }}
                  onClick={() => handleImageClick('Home93')}
                />
                <img
                  src="/images/Home94.svg"
                  alt="Home51"
                  style={{ width: '65%', height: 'auto', cursor: 'pointer' }}
                  onClick={() => handleImageClick('Home94')}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pr: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
            pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '0rem' },
            pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '1rem' },
          }}>
            <Box sx={{
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
              height: 'auto',
            }}>
              <img
                src="/images/Home91.webp"
                alt="Home51"
                style={{ width: '100%', height: 'top' }}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: '0rem', alignItems: 'center', pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' } }}>
          <Typography variant="h1" sx={{
            fontWeight: 'bold',
            fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' },
            color: '#000000',
            mt: { xs: 5, sm: 0, md: 10, lg: 1 },
            mb: { xs: 2, sm: 5, md: 2, lg: 5 },
            textAlign: 'center',
          }}>
            {translations[language]["Accedi al tuo conto dal Web."]}
          </Typography>
        </Box>

        <Box sx={{ mt: '2rem', alignItems: 'center', px: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' } }}>
          <Typography variant="h2" sx={{
            fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
            color: '#000000',
            mt: { xs: 5, sm: 0, md: 10, lg: 1 },
            mb: { xs: 2, sm: 5, md: 2, lg: 10 },
            textAlign: 'center',
          }}>
            {translations[language]["Tutti i nostri conti includono l’accesso dal Web con un’interfaccia semplice che ti permette di effettuare tutte le operazioni consentite dall’ App, perfetto per i tablet, veloce e sicuro."]}
          </Typography>
        </Box>

        <Box sx={{
          width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          mb: { xs: '0.5rem', sm: '0.5rem', md: '0.5rem', lg: '0.5rem' },
        }}>
          <img
            src="/images/Home977.webp"
            alt="Home51"
            style={{ width: '70%', height: 'auto' }}
          />
        </Box>
      </Box>
      <Box sx={{backgroundColor: '#dfe0e2', padding: '2rem', textAlign: 'center' }}>

        <Box sx={{ mb: '2rem', mt: '2rem', width: '100%' }}>
          <Container maxWidth="md" sx={{ width: { xs: '100%', sm: '80%', md: '60%', lg: '60%' } }}>

            {/* Question 1 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '14px', height: '14px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Carta Reale è una Banca?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Carta Reale non è una banca, perché non utilizza il tuo denaro, ma offre servizi bancari esclusivi e tecnologici tramite web e smartphone. Con il conto Carta Reale puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit, un’azienda globale con licenza di Istituto di moneta elettronica, leader del settore dei servizi bancari ad alta tecnologia"]}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Question 2 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '16px', height: '16px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Posso usarla dal computer?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Carta Reale offre ai propri clienti l’accesso da APP Android e IOS e dal computer con tutti i browser da my.cartareale.it senza costi aggiuntivi."]}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Question 3 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '16px', height: '16px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Posso Prelevare all'ATM?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Con Carta Reale puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti** sugli ATM dotati di contactless."]}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Question 4 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '16px', height: '16px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Posso aggiungerlo a Google Pay o Apple Pay?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Tutte le nostre carte sono sul circuito Mastercard Debit e possono essere usate con i wallet di Apple e Google."]}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Question 5 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '16px', height: '16px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Che altre funzionalità offre Carta Reale?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Carta Reale offre numerose altre funzionalità di pagamento come l’acquisto dei vouchers dei marchi più prestigiosi, le ricariche telefoniche di tutti gli operatori, pagamento di bollettini premarcati, Pago PA, MAV e RAV. Puoi avere la carta fisica e 7 carte virtuali per ogni piano."]}
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Question 6 */}
            <Accordion sx={{ backgroundColor: 'black', color: 'white', mb: 2 }}>
              <AccordionSummary expandIcon={<AddIcon sx={{ color: 'white' }} />}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src="/images/Home101.webp" alt="Question Icon" style={{ width: '16px', height: '16px', marginRight: '1rem' }} />
                  <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, fontWeight: 'bold', flexGrow: 1 }}>
                    {translations[language]["Che assistenza offre Carta Reale?"]}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: 'white' }}>
                <Typography sx={{ fontSize: { xs: '1rem', sm: '1rem', md: '1.25rem', lg: '1.25rem' }, color: 'black' }}>
                  {translations[language]["Carta Reale offre un portale completo di domande e risposte e una chat con operatori  in tempo reale per darti un’assistenza rapida e un’esperienza d’uso impareggiabile."]}
                </Typography>
              </AccordionDetails>
            </Accordion>

          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Homepage;