import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './App';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './components/Theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{
        maxWidth: '1920px',
        margin: '0 auto',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <AppWrapper />
      </div>
    </ThemeProvider>
  </React.StrictMode>
);