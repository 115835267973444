import React from 'react';
import { Box } from '@mui/material';

const Phone_topups = () => {
 
  return (
    <Box sx={{ pt: '200px' }}> {/* Adjust the value based on your navbar's actual height */}
    {/* Home page content goes here */}
    <h1>phone ups</h1> 




    <h1>phone ups</h1> 
    


    </Box>
  );
};





export default Phone_topups;
